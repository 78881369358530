import React, { FC, useState } from 'react'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import {
  CreateNewArrivals,
  DeleteNewArrivals,
  FindNewArrivals,
  UpdateNewArrivals
} from '../graphql/new-arrivals.graphql'
import { Button, message, Space, Table } from 'antd'
import { translate } from '../../../core'
import { InputCard } from '../../inventory/component/InputCard'
import { NewArrivals } from '../type/new-arrivals'
import { QueryPageableRequest, QueryPageableResponse } from '../../../core/type'
import { Item, ItemType } from '../../inventory/type'
import { ItemFindRequest } from '../../inventory/type/item-query'
import { GetItemsForUpdateImages } from '../../inventory/graphql/item.graphql'
import { Combo, DraggableRow, DraggableTable, DragHandle } from '../../../core/component'
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { arrayMove } from '@dnd-kit/sortable'
import { DragEndEvent } from '@dnd-kit/core'

export const NewArrivalsList: FC = () => {
  const {loading} = useQuery(FindNewArrivals, {
    notifyOnNetworkStatusChange: true,
    onCompleted: result => setData(
      (result?.newArrivalsFind ?? []).map(it => ({...it, key: it.displayOrder.toString()}))
    )
  })
  const [data, setData] = useState<NewArrivals[]>([])
  const [itemsFind] = useLazyQuery<QueryPageableResponse<Item, 'itemsFind'>, QueryPageableRequest<ItemFindRequest>>(GetItemsForUpdateImages)
  const [newItemId, setNewItemId] = useState<number>()
  const [messageApi, contextHolder] = message.useMessage()
  const [create, {loading: creating}] = useMutation(CreateNewArrivals, {
    refetchQueries: [FindNewArrivals],
    onCompleted: () => messageApi.success(translate('new_arrivals', 'save_success_message')),
    onError: (err) => messageApi.error(err.message),
  })

  const [update] = useMutation(UpdateNewArrivals, {
    refetchQueries: [FindNewArrivals],
    onCompleted: () => messageApi.success(translate('new_arrivals', 'save_success_message')),
  })

  const [deleteRow] = useMutation(DeleteNewArrivals, {
    refetchQueries: [FindNewArrivals],
    onCompleted: () => messageApi.success(translate('new_arrivals', 'save_success_message')),
  })

  const onDragEnd = async ({active, over}: DragEndEvent) => {
    if (active.id === over?.id)
      return

    setData((prevState) => {
      const activeIndex = prevState.findIndex((record) => record.key === active?.id)
      const overIndex = prevState.findIndex((record) => record.key === over?.id)
      const newItems = arrayMove(prevState, activeIndex, overIndex)

      update({
        variables: {
          input: newItems.map(({id}, index) => ({
            id,
            displayOrder: index,
          }))
        }
      })

      return newItems
    })
  }

  return (
    <InputCard className='content-size'>
      <Space>
        <Combo<Item, number>
          value={newItemId}
          style={{width: 300}}
          onChange={value => setNewItemId([value].flat()[0])}
          fetch={async ({request, search}) => {
            const {data} = await itemsFind({
              variables: {
                request: {
                  sku: search,
                  skip: request?.skip ?? 0,
                  take: request?.take ?? 10,
                  ...request,
                  showNoEffects: false,
                  showOnWeb: true,
                  types: [ItemType.Giftware, ItemType.Tableware, ItemType.Jewelery, ItemType.Watch],
                },
              },
            })

            const list = data?.itemsFind.data ?? []

            return list.map(e => ({
              value: e.id || 0,
              display: `${e.sku} - ${e.description}`,
              item: e || {},
            }))
          }}
        />
        <Button
          loading={creating}
          disabled={creating || !newItemId}
          type='primary'
          icon={<PlusOutlined/>}
          onClick={async () => {

            await create({
              variables: {
                input: {
                  itemId: newItemId ?? 0,
                  displayOrder: 0,
                }
              },
            })

            setNewItemId(undefined)
          }}
        >
          {translate('add')}
        </Button>
      </Space>
      <DraggableTable
        items={data}
        onDragEnd={onDragEnd}
      >
        <Table<NewArrivals>
          rowKey='key'
          className='mt-2'
          loading={loading}
          components={{body: {row: DraggableRow}}}
          columns={[
            {
              key: 'sort',
              align: 'center',
              width: 10,
              render: () => <DragHandle/>
            },
            {
              dataIndex: 'item',
              title: translate('sku'),
              width: 100,
              render: (_, {item}) => item.sku,
            },
            {
              dataIndex: 'item',
              title: translate('description'),
              width: '100%',
              render: (_, {item}) => item.description,
            },
            {
              dataIndex: 'id',
              key: 'action',
              width: 10,
              render: id => (
                <Button
                  type='text'
                  shape='circle'
                  icon={<DeleteOutlined/>}
                  onClick={() => deleteRow({
                    variables: {
                      id,
                    },
                  })}
                />
              )
            }
          ]}
          dataSource={data}
          pagination={false}
        />
      </DraggableTable>
      {contextHolder}
    </InputCard>
  )
}