import { gql, TypedDocumentNode } from "@apollo/client";
import { NewArrivals, NewArrivalsCreateInput, NewArrivalsUpdateInput } from '../type/new-arrivals'

export const FindNewArrivals: TypedDocumentNode<{newArrivalsFind: NewArrivals[]}> = gql`
    query FindNewArrivals {
        newArrivalsFind {
            id
            item {
                id
                sku
                description
            }
            displayOrder
        }
    }
`

export const CreateNewArrivals: TypedDocumentNode<void, {input: NewArrivalsCreateInput}> = gql`
    mutation CreateNewArrivals ($input: NewArrivalsCreateInput!) {
        newArrivalsCreate(input: $input)
    }
`

export const UpdateNewArrivals: TypedDocumentNode<void , {input: NewArrivalsUpdateInput[]}>= gql`
    mutation UpdateNewArrivals ($input: [NewArrivalsUpdateInput !]!) {
        newArrivalsUpdate(input:$input)
    }
`

export const DeleteNewArrivals: TypedDocumentNode<void, {id: number}> = gql`
    mutation DeleteNewArrivals ($id: Int!) {
        newArrivalsDelete(id: $id)
    }
`