import { Image } from 'antd';
import { resolvePathFile } from '../../until';
import React from 'react';

export const ImageViewer = ({ mediaURL, width, height, mediaType }: ImageViewerProps) => {
  if (mediaType === 'video') {
    return (
      <div className='d-flex flex-column justify-content-center align-items-center h-100'>
        <video width={width} height={height} controls>
          <source src={resolvePathFile(mediaURL)} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  return (
    <div className='d-flex flex-column justify-content-center align-items-center h-100'>
      <Image
        onClick={e => e.stopPropagation()}
        width={width}
        height={height}
        src={resolvePathFile(mediaURL)}
      />
    </div>
  );
};

interface ImageViewerProps {
  mediaURL: string;
  width: number | string;
  height: number | string;
  mediaType?: 'image' | 'video';
}
