import { Button, Tooltip, Upload, UploadFile, UploadProps } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { translate } from "../../translate";
import { useState } from "react";
import { ImageEntry, UploadingStatus } from "../../../app/inventory/type";
import { readUrlAsync } from "../../until";
import { UploadVideoResponse } from "../../../app/shared/type";
import { uploaderService } from "../../../app/shared/service";
import { Image, Video } from "../../type";
import { notify } from "../..";

export const VideoUploaderButton = ({
  onUploaded,
}: VideoUploaderButtonProps) => {
  const [uploading, setUploading] = useState<boolean>(false);

  const props: UploadProps = {
    beforeUpload: async (file) => {
      if (file.type.includes('mp4')) {
        const video: ImageEntry = {
          name: file.name,
          status: UploadingStatus.Pending,
          file,
          url: await readUrlAsync(file),
        };

        const result = await handleUpload(video);
        onUploaded({
          ...result
        });

        return false;
      } else {
        notify.error(translate("error"), translate("upload_format"));
      }
    },
    showUploadList: false,
  };

  const handleUpload = async (image: ImageEntry): Promise<UploadVideoResponse> => {
    setUploading(true)

    try {
      const [uploadResult] = await uploaderService.uploadVideoFiles([
        image.file,
      ] as UploadFile[]);
      console.log(uploadResult);
      
      return {
        fileName: uploadResult.filename,
      };
    } finally {
      setUploading(false);
    }
  };

  return (
    <Upload {...props}>
      <Tooltip title={translate("upload")}>
        <Button
          icon={<UploadOutlined />}
          loading={uploading}
          disabled={uploading}
        >
          {uploading ? "Uploading..." : ""}
        </Button>
      </Tooltip>
    </Upload>
  );
};

export interface VideoUploaderButtonProps {
  onUploaded: (video: Video) => void;
}
