import * as React from 'react'
import { FC } from 'react'
import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
import qs from 'qs'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { memory } from '../index'
import { TokenResponse } from '../../app/auth/type'
import { AUTHENTICATION_TOKEN } from '../../App.constants'

export type PdfViewerProps = {
  url: string
  params?: Record<string, any>
  skip?: boolean
}

export const PdfViewer: FC<PdfViewerProps> = ({url, params, skip}) => {
  const defaultLayoutPluginInstance = defaultLayoutPlugin()
  const token = memory.get<TokenResponse>(AUTHENTICATION_TOKEN)

  const resolveURL = () => {
    const stringify = qs.stringify(params)
    const queryString = stringify ? `?${stringify}` : ''
    return `${process.env.REACT_APP_API_BASE_URL}/v1/${url}${queryString}`
  }

  return (
    <div className='p-3 pdf-viewer-page'>
      {
        !skip
        && <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.js'>
          <div
            style={{
              height: '100%',
              width: '100%',
            }}
          >
            <Viewer
              fileUrl={resolveURL()}
              httpHeaders={{
                Authorization: `${token?.token_type} ${token?.access_token}`,
              }}
              plugins={[defaultLayoutPluginInstance]}
              defaultScale={SpecialZoomLevel.ActualSize}
            />
          </div>
        </Worker>
      }
    </div>
  )
}
