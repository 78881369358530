import { FC } from 'react'
import { PdfViewer, PdfViewerProps } from '../PdfViewer'
import { Modal } from 'antd'

export type ReportPdfViewerProps = Omit<PdfViewerProps, 'url'> & {
  name: string
  title: string
  open: boolean
  onCancel: () => void
}

export const ReportPdfViewer: FC<ReportPdfViewerProps> = ({name, open, title, onCancel, ...rest}) => {
  return (
    <Modal
      open={open}
      title={title}
      width='60%'
      onCancel={onCancel}
      footer={[]}
    >
      <PdfViewer
        url={`report/exportPdf/${name}`}
        {...rest}
      />
    </Modal>
  )
}