import { ImageEntry, ItemImage, UploadingStatus } from '../type'
import { Button, List, Row, Tooltip, Upload, UploadFile, UploadProps } from 'antd'
import React, { useState } from 'react'
import { FormEntryField } from '../../../core/type'
import { readUrlAsync } from '../../../core/until'
import { ImageViewer } from '../../../core/component/Upload/ImageViewer'
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons'
import { translate } from '../../../core'
import { UploadImageResponse } from '../../shared/type'
import { uploaderService } from '../../shared/service'

export const ItemFormUpdateImagesEntry = ({value, onChange}: FormEntryField<ItemImage[]>) => {
  const [uploading, setUploading] = useState<boolean>(false)

  const props: UploadProps = {
    beforeUpload: async (file) => {
      const image: ImageEntry = {
        name: file.name,
        status: UploadingStatus.Pending,
        file,
        url: await readUrlAsync(file),
      }

      const result = await handleUpload(image)
      image.status = UploadingStatus.Uploaded
      image.url = result.filename
      image.item = result

      onChange!([
        ...value ?? [],
        {
          filename: result.filename,
          originalFilename: result.originalFilename,
        },
      ])
      return false
    },
    showUploadList: false,
  }

  const upload = (files: UploadFile[]): Promise<UploadImageResponse[]> => {
    return uploaderService.uploadImages(files)
  }

  const handleUpload = async (image: ImageEntry): Promise<UploadImageResponse> => {
    setUploading(true)

    try {
      const [result] = await upload([image.file] as UploadFile[])
      return result
    } finally {
      setUploading(false)
    }
  }

  const handleRemove = (item: ItemImage) => {
    onChange!(value?.filter(it => it !== item))
  }

  return (
    <>
      <Row>
        <List
          itemLayout='horizontal'
          dataSource={value ?? []}
          renderItem={(item: ItemImage) => (
            <List.Item
              actions={[
                <Tooltip
                  title={translate('remove', 'image')}
                >
                  <Button
                    onClick={() => handleRemove(item)}
                    danger
                    size="middle"
                    shape="circle"
                    icon={<DeleteOutlined/>}
                  />
                </Tooltip>,
              ]}>
              <ImageViewer
                mediaURL={item.filename}
                width={100}
                height={100}/>
            </List.Item>
          )}
        />
      </Row>
      <Row>
        <Upload {...props}>
          <Button
            icon={<UploadOutlined/>}
            loading={uploading}
            disabled={uploading}
          >
            {translate('upload', 'image')}
          </Button>
        </Upload>
      </Row>
    </>
  )
}