import React, { FC, useContext, useMemo } from 'react'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { DndContext, DragEndEvent } from '@dnd-kit/core'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities'
import { Button } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { CSS } from '@dnd-kit/utilities'

type RowContextProps = {
  setActivatorNodeRef?: (element: HTMLElement | null) => void
  listeners?: SyntheticListenerMap
}

const RowContext = React.createContext<RowContextProps>({})

export const DragHandle: React.FC = () => {
  const {setActivatorNodeRef, listeners} = useContext(RowContext)
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined/>}
      style={{cursor: 'move'}}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  )
}

type RowProps = React.HTMLAttributes<HTMLTableRowElement> & {
  'data-row-key': string
}

export const DraggableRow: FC<RowProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({id: props['data-row-key']})

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging ? {position: 'relative', zIndex: 9999} : {}),
  }

  const contextValue = useMemo<RowContextProps>(
    () => ({setActivatorNodeRef, listeners}),
    [setActivatorNodeRef, listeners],
  )

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  )
}

export type DraggableTableProps = {
  items: { key: string }[]
  children: React.ReactNode
  onDragEnd: (event: DragEndEvent) => void
}

export const DraggableTable: FC<DraggableTableProps> = ({items, children, onDragEnd}) => {
  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        items={items.map(it => it.key)}
        strategy={verticalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </DndContext>
  )
}