import { FormEntryField, Image, Video } from "../../../core/type";
import uploadImageSvg from "../../../assets/upload-image.svg";
import { useState } from "react";
import { Button, Carousel, Space, Tooltip } from "antd";
import { ImageViewer } from "../../../core/component/Upload/ImageViewer";
import { DeleteOutlined } from "@ant-design/icons";
import { translate } from "../../../core";
import { VideoUploaderButton } from "../../../core/component/Upload/VideoUploaderButton";

export const ItemFormVideos = ({
  value,
  onChange,
}: FormEntryField<Video[]>) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  
  return (
    <Space direction="vertical">
      <Space className="d-flex flex-row justify-content-center">
        <VideoUploaderButton onUploaded={(video) => onChange!([...(value ?? []), video])} />
        <Tooltip title={translate("remove", "current", "image")}>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() =>
              onChange!(value?.filter((e) => e !== value[currentIndex]))
            }
          />
        </Tooltip>
      </Space>
      <div>
        {value && value.length > 0 ? (
          <Carousel
            afterChange={setCurrentIndex}
            dotPosition="right"
            style={{ width: 300, height: 200 }}
          >
            {value.map(({ fileName: filename }) => (
              <div className="bg-dark" key={Date.now()}>
                <ImageViewer
                  mediaURL={filename}
                  width={250}
                  height={200}
                  mediaType="video"
                />
              </div>
            ))}
          </Carousel>
        ) : (
          <EmptyImage />
        )}
      </div>
    </Space>
  );
};

const EmptyImage = () => (
  <div className="w-100 d-flex flex-row justify-content-center">
    <img
      src={uploadImageSvg}
      alt="upload"
      style={{ width: 164, height: 164 }}
    />
  </div>
);
