import React, { useState } from 'react'
import { Combo, Container, StimulsoftViewer } from '../../../../core/component'
import { Button, Checkbox, DatePicker, Form, Input, Space } from 'antd'
import { translate } from '../../../../core'
import { dateFormat } from '../../../../core/until'
import { InputCard } from '../../../inventory/component/InputCard'
import { SalesByVendorRequest } from '../type/vendor-report'
import { ItemType } from '../../../inventory/type'
import { getItemTypeComboOptions } from '../../../inventory/func/type-options'

export const SalesByVendorPageServerRendering = () => {
  const [form] = Form.useForm<SalesByVendorRequest>()
  const [request, setRequest] = useState<Omit<SalesByVendorRequest, 'minDate'| 'maxDate'> & {
    minDate?: string
    maxDate?: string
  }>()

  return (
    <>
      <div className='print-hidden'>
        <Container
          leftClassName='action-container'
          paddingClass='0'
        >
          <InputCard className='content-size'>
            <Form
              layout='vertical'
              form={form}
              onFinish={data => setRequest({
                ...data,
                minDate: data.minDate?.toISOString(),
                maxDate: data.maxDate?.toISOString(),
              })}
              className='d-flex flex-column gap-2'
            >
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('type')}</label>
                <Form.Item
                  className='mb-1'
                  name='type'
                >
                  <Combo<any, ItemType>
                    allowMultiple
                    size='middle'

                    options={getItemTypeComboOptions()}
                    style={{width: 400}}

                  />
                </Form.Item>
              </Space>
              <Space className='w-100' size='middle'>
                <label style={{width: 100}}></label>
                <label style={{width: 192}}>{translate('from')}</label>
                <label style={{width: 180}}>{translate('to')}</label>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('vendor')}</label>
                <Form.Item
                  className='mb-1'
                  name='vendorIdFrom'
                >
                  <Input style={{width: 192}}/>
                </Form.Item>
                <Form.Item
                  className='mb-1'
                  name='vendorIdTo'
                >
                  <Input style={{width: 192}}/>
                </Form.Item>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('date')}</label>

                <Form.Item
                  name='minDate'
                  className='mb-1'
                  rules={[
                    {required: true, message: translate('min', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>

                <Form.Item
                  name='maxDate'
                  className='mb-1'
                  rules={[
                    {required: true, message: translate('max', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>
              </Space>
              <Space className='w-100 ' size='middle'>
                <Form.Item
                  name='chinaVersion'
                  valuePropName='checked'
                >
                  <Checkbox>{translate('China version')}</Checkbox>
                </Form.Item>
              </Space>
              <Space className='gap-1'>
                <Button
                  onClick={() => form.resetFields()}
                >
                  {translate('clear')}
                </Button>
                <Button
                  type='primary'
                  onClick={form.submit}
                >
                  {translate('print')}
                </Button>
              </Space>
            </Form>
          </InputCard>
        </Container>

        <StimulsoftViewer
          name='sales-by-vendor'
          request={request}
          skip={!request}
        />
      </div>
    </>
  )
}
