import React, { useState } from 'react'
import {
  Combo,
  Container,
  enumToComboOptionTransformer,
} from '../../../../core/component'
import { Button, DatePicker, Form, Space } from "antd";
import { translate } from "../../../../core";
import { dateDisplay, dateFormat, standardFormat } from "../../../../core/until";
import { useLazyQuery } from "@apollo/client";
import { InputCard } from "../../../inventory/component/InputCard";
import { OrderPaymentRequest, OrderPaymentType, OrderPaymentVariables } from "../../../order/type";
import { GetOrderPaymentsDocuments } from "../../../order/graphql/order-report-graphql";
import OrderPaymentsReport from "./OrderPaymentsReport";
import { IGetOrderReport } from "../type/order-payments-report";

export const OrderPaymentsLegacy = () => {
  const [getOrderPayments, {loading}] = useLazyQuery<{ orderPayments: unknown }, {
    request: OrderPaymentVariables
  }>(GetOrderPaymentsDocuments)
  const [data, setData] = useState<IGetOrderReport>()
  const [form] = Form.useForm<OrderPaymentRequest>()

  const handleOK = async (values: OrderPaymentRequest) => {
    const {data} = await getOrderPayments({
      variables: {
        request: {
          type: values.type,
          minDate: values.minDate ? dateDisplay(values.minDate.toDate(), standardFormat) : "",
          maxDate: values.maxDate ? dateDisplay(values.maxDate.toDate(), standardFormat) : "",
        }
      }
    })

    if (data) {
      setData(data as IGetOrderReport)
    } else {
      setData(undefined)
    }

  }

  return (
    <>
      <div className='print-hidden'>
        <Container
          leftClassName="action-container"
          paddingClass="0"
          loading={loading}
        >
          <InputCard className="content-size">
            <Form
              layout='vertical'
              form={form}
              onFinish={handleOK}
            >
              <Form.Item
                name='type'
                label={translate('type')}
                rules={[
                  {required: true, message: translate('type', 'is_required')}
                ]}
                style={{maxWidth: 300}}
              >
                <Combo<any, OrderPaymentType>
                  size="middle"
                  options={enumToComboOptionTransformer(OrderPaymentType)}

                />
              </Form.Item>

              <Space className='w-100'>
                <Form.Item
                  name='minDate'
                  label={translate('min', 'date')}
                  rules={[
                    {required: true, message: translate('min', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: "100%"}}/>
                </Form.Item>

                <Form.Item
                  name='maxDate'
                  label={translate('max', 'date')}
                  rules={[
                    {required: true, message: translate('max', 'date', 'is_required')}
                  ]}
                >
                  <DatePicker format={dateFormat} style={{width: "100%"}}/>
                </Form.Item>
              </Space>
              <Space className='gap-1'>
                <Button
                  onClick={() => form.resetFields()}
                >
                  {translate('clear')}
                </Button>
                <Button
                  type='primary'
                  onClick={form.submit}
                >
                  {translate('print')}
                </Button>
              </Space>
            </Form>
          </InputCard>

        </Container>
      </div>
      <OrderPaymentsReport data={data}/>
    </>
  )
}
