import { FormEntryField } from '../../type';
import { ImageEntry, UploadingStatus } from '../../../app/inventory/type';
import { Button, Space, Tooltip, Upload, UploadFile, UploadProps } from 'antd';
import { readUrlAsync } from '../../until';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { translate } from '../../translate';
import { ImageViewer } from './ImageViewer';
import { uploaderService } from '../../../app/shared/service';
import React, { useState } from 'react';
import { UploadImageResponse } from '../../../app/shared/type';
import { PdfIcon } from '../../../component/icon';

export type UploaderProps = FormEntryField<ImageEntry> & {
  buttonLabel?: string
  fileType?: 'Image' | 'Pdf'
}
export const Uploader = ({ value, onChange, buttonLabel, fileType }: UploaderProps) => {
  const [ uploading, setUploading ] = useState<boolean>(false)

  const props: UploadProps = {
    beforeUpload: async (file) => {
      const image: ImageEntry = {
        name: file.name,
        status: UploadingStatus.Pending,
        file,
        url: await readUrlAsync(file)
      }

      const result = await handleUpload(image)
      image.status = UploadingStatus.Uploaded
      image.url = result.filename
      image.item = result

      onChange!(image)
      return false
    },
    showUploadList: false
  }

  const upload = (files: UploadFile[]): Promise<UploadImageResponse[]> => {
    const type = fileType || 'Image'
    if (type === 'Image')
      return uploaderService.uploadImages(files)

    if (type === 'Pdf')
      return uploaderService.uploadPdfFiles(files)

    return Promise.resolve([])
  }

  const handleUpload = async (image: ImageEntry): Promise<UploadImageResponse> => {
    setUploading(true)

    try {
      const [ result ] = await upload([ image.file ] as UploadFile[])
      return result
    } finally {
      setUploading(false)
    }
  }

  return (
    <div className='d-flex justify-content-center align-items-center h-100'>
      {
        value
          ? <Space direction='vertical'>
          <div className='w-100 d-flex justify-content-end'>
            <Tooltip
              title={ translate('remove', 'image') }
            >
              <Button
                onClick={ () => onChange!(undefined) }
                danger
                size='middle'
                shape='circle'
                icon={ <DeleteOutlined/> }
              />
            </Tooltip>
          </div>
            {
              (fileType || 'Image') === 'Image'
                ? <ImageViewer
                  mediaURL={ value.url }
                  width={ 200 }
                  height={ 200 }
                />
                : <Tooltip placement='right' title={ translate('click_to_download') }>
                  <a href={ uploaderService.downloadPdfUrl(value.url) }>
                    <PdfIcon size={ 50 }/>
                  </a>
                </Tooltip>
            }
          </Space>
          : <Upload { ...props }>
            <Button
              icon={ <UploadOutlined/> }
              loading={ uploading }
              disabled={ uploading }
            >
              { buttonLabel || translate('upload', 'image') }
            </Button>
          </Upload>
      }
    </div>
  )
}

