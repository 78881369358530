import { Item, ItemEntry, ItemRelation, RelationType } from '../type'
import { toMoment } from '../../../core/until';

export const itemEntityToEntry = (entity: Item): ItemEntry => {
  const { jewelery, watch, stone, giftware, sales, tableware } = entity

  return {
    dateOfPurchase: toMoment(entity.dateOfPurchase),
    patternNumber: entity.patternNumber,
    sku: entity.sku,
    style: entity.style,
    type: entity.type,
    description: entity.description,
    catalogDescription: entity.catalogDescription,
    notes: entity.notes,
    groupId: entity.group?.id,
    vendorId: entity.vendor?.id,
    designer: entity.designer,
    costPrice: entity.costPrice,
    listPrice: entity.listPrice,
    oursPrice: entity.oursPrice,
    costSale: entity.costSale,
    listSale: entity.listSale,
    oursSale: entity.oursSale,
    saleStartDate: toMoment(entity.saleStartDate),
    saleEndDate: toMoment(entity.saleEndDate),
    quantity: entity.quantity,
    showOnline: entity.showOnline,
    mounting: entity.mounting,
    jewelery: {
      ...jewelery,
      stones: jewelery?.stones?.map(st => ({
        name: st.name,
        color: st.color,
        clarity: st.clarity,
        shape: st.shape,
        cut: st.cut,
        averageWeight: st.averageWeight,
        quantity: st.quantity,
        totalWeight: st.totalWeight,
        costCT: st.costCT,
        totalCost: st.totalCost
      }))
    },
    watch: watch,
    stone: stone
      ? {
        ...stone,
        parcelNumber: entity.group?.title,
        certificateDate: toMoment(stone.certificateDate)
      }
      : null,
    giftware: giftware
      ? {
        discounted: giftware.discounted,
        discontinuedDate: toMoment(giftware.discontinuedDate),
        dimensions: giftware.dimensions,
        sequence: giftware.sequence,
        patternNumber: giftware.patternNumber,
      }
      : undefined,
    tableware: tableware
      ? {
        discounted: tableware.discounted,
        discontinuedDate: toMoment(tableware.discontinuedDate),
        dimensions: tableware.dimensions,
        sequence: tableware.sequence,
        patternNumber: tableware.patternNumber,
      }
      : undefined,
    sales: sales,
    images: entity.images?.map(e => ({
      filename: e.filename,
      originalFilename: e.originalFilename,
      priority: e.priority
    })),
    videos: entity.videos?.map(e => ({
      fileName: e.fileName,
    })),
    vrnPackages: entity.vrnPackages,
    relations: [
      ...entity.components.map<ItemRelation>(e => ({
        type: RelationType.Child,
        item: e.component,
        quantity: e.quantity
      })),
      ...entity.parents.map<ItemRelation>(e => ({
        type: RelationType.Parent,
        item: e.item,
        quantity: e.quantity
      })),
    ],
    parents: entity.parents,
    category: {
      categoryId: entity.category?.id,
      subcategoryId: entity.subcategory?.id,
      subcategory2Id: entity.subcategory2?.id,
    }
  }
}

