import React, { useState } from 'react'
import { Container } from '../../../../core/component'
import { Button, DatePicker, Form, Input, Space } from 'antd'
import { translate } from '../../../../core'
import { dateDisplay, dateFormat, dateFormatSlashed } from '../../../../core/until'
import { InputCard } from '../../../inventory/component/InputCard'
import { SalesByCustomerRequest } from '../type/customer-report'
import SalesByCustomerReport from './SalesByCustomerReport';
import { useLazyQuery } from '@apollo/client'
import { GetSalesByCustomer } from '../../../contact/graphql/contact.queries.graphql'

export const SalesByCustomerLegacy = () => {
  const [getPrint, {loading}] = useLazyQuery<{ salesByCustomer: any }>(GetSalesByCustomer)
  const [form] = Form.useForm<SalesByCustomerRequest>()
  const [data, setData] = useState<any>()

  const handleOK = async (values: SalesByCustomerRequest) => {
    const response = await getPrint({
      variables: {
        request: {
          minDate: values.minDate ? dateDisplay(values.minDate.toDate(), dateFormatSlashed) : undefined,
          maxDate: values.maxDate ? dateDisplay(values.maxDate.toDate(), dateFormatSlashed) : undefined,
          customerNumberForm: values.customerNumberForm,
          customerNumberTo: values.customerNumberTo
        }
      }
    })
    if (response.data) {
      setData(response?.data)
    } else {
      setData(undefined)
    }
  }

  return (
    <>
      <div className='print-hidden'>
        <Container
          leftClassName='action-container'
          paddingClass='0'
        >
          <InputCard className='content-size'>
            <Form
              layout='vertical'
              form={form}
              onFinish={handleOK}
              className='d-flex flex-column gap-2'
            >
              <Space className='w-100' size='middle'>
                <label style={{width: 100}}></label>
                <label style={{width: 180}}>{translate('from')}</label>
                <label style={{width: 180}}>{translate('to')}</label>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('customer', 'No')}</label>
                <Form.Item
                  className='mb-1'
                  name='customerNumberForm'
                >
                  <Input/>
                </Form.Item>
                <Form.Item
                  className='mb-1'
                  name='customerNumberTo'
                >
                  <Input/>
                </Form.Item>
              </Space>
              <Space className='w-100 ' size='middle'>
                <label style={{width: 100}}>{translate('date')}</label>

                <Form.Item
                  name='minDate'
                  className='mb-1'

                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>

                <Form.Item
                  name='maxDate'
                  className='mb-1'

                >
                  <DatePicker format={dateFormat} style={{width: 192}}/>
                </Form.Item>
              </Space>
              <Space className='gap-1'>
                <Button
                  onClick={() => form.resetFields()}
                >
                  {translate('clear')}
                </Button>
                <Button
                  loading={loading}
                  type='primary'
                  onClick={form.submit}
                >
                  {translate('print')}
                </Button>
              </Space>
            </Form>
          </InputCard>
        </Container>
      </div>
      <SalesByCustomerReport data={data}/>
    </>
  )
}
