import { UploadFile } from 'antd';
import { UploadImageResponse, UploadVideoResponse } from '../type';
import { RcFile } from 'antd/es/upload';
import { httpRequest } from '../../../core';
import { HttpMethod } from '../../../core/type';

export class UploaderService {
  uploadImages(files: UploadFile[]): Promise<UploadImageResponse[]> {
    const data = new FormData();
    files.forEach(file => data.append('images', file as RcFile));

    return httpRequest.execute<UploadImageResponse[]>({
      method: HttpMethod.POST,
      url: 'files/image',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: data
    });
  }

  uploadPdfFiles(files: UploadFile[]): Promise<UploadImageResponse[]> {
    const data = new FormData();
    files.forEach(file => data.append('files', file as RcFile));

    return httpRequest.execute<UploadImageResponse[]>({
      method: HttpMethod.POST,
      url: 'files/pdf',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: data
    });
  }

  uploadVideoFiles(files: UploadFile[]): Promise<UploadImageResponse[]> {
    const data = new FormData();
    files.forEach(file => data.append('videos', file as RcFile));

    return httpRequest.execute<UploadImageResponse[]>({
      method: HttpMethod.POST,
      url: 'files/video',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: data,
    });
  }


  downloadPdfUrl(fileName: string): string {
    return `${ httpRequest.baseURL }/files/pdf/${ fileName }`
  }
}
