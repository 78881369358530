import React, { FC, useState } from 'react'
import { translate } from '../../../core'
import { AutoComplete, Button, Checkbox, Form, Space } from 'antd'
import { InventoryByManufactureRequest, ItemType } from '../type'
import { Combo, Container, StimulsoftViewer } from '../../../core/component'
import { getItemTypeComboOptions } from '../func/type-options'
import { InputCard } from './InputCard'
import { useQuery } from '@apollo/client'
import { GetVendorsForLookup } from '../../contact/graphql/contact.queries.graphql'
import { FormEntryField } from '../../../core/type'

export const VendorAutoComplete:FC<FormEntryField<string>> = ({value, onChange})=> {
  const [search, setSearch] = useState<string>('')
  const {data} = useQuery(GetVendorsForLookup, {
    variables: {
      request: {
        contactId: search,
        take: 20,
        skip: 0,
      },
    },
  })

  return(
    <AutoComplete
      value={value}
      onChange={onChange}
      options={data?.contactsFind.data.map(v => ({label: v.contactId, value: v.contactId})) ?? []}
      style={{width: 300}}
      onSearch={setSearch}
    />
  )
}

export const InventoryByManufactureReport: FC = () => {
  const [form] = Form.useForm<InventoryByManufactureRequest>()
  const [request, setRequest] = useState<Record<string, any>>()

  return (
    <>
      <Container
        leftClassName='action-container'
        paddingClass='0'
      >
        <InputCard className='content-size'>
          <Form
            initialValues={{inStock: true}}
            layout='vertical'
            form={form}
            onFinish={setRequest}
            className='d-flex flex-column gap-2 w-50'
          >
            <Form.Item
              name='type'
              label={translate('type')}
            >
              <Combo<any, ItemType>
                allowMultiple
                size="middle"
                options={getItemTypeComboOptions()}
              />
            </Form.Item>

            <div style={{fontSize: 17}}>
              {translate('manufacture', 'or', 'vendor')}
            </div>

            <Space className='w-100'>
              <Form.Item
                name='vendorContactIdFrom'
                label={translate('from')}
              >
                <VendorAutoComplete/>
              </Form.Item>

              <Form.Item
                className='ms-3'
                name='vendorContactIdTo'
                label={translate('to')}
              >
                <VendorAutoComplete/>
              </Form.Item>
            </Space>

            <Form.Item name='inStock' valuePropName='checked'>
              <Checkbox>{translate('in_stock_only')}</Checkbox>
            </Form.Item>

            <Space className='gap-1'>
              <Button
                onClick={() => form.resetFields()}
              >
                {translate('clear')}
              </Button>
              <Button
                type='primary'
                onClick={form.submit}
              >
                {translate('print')}
              </Button>
            </Space>
          </Form>
        </InputCard>
      </Container>

      <StimulsoftViewer
        name='inventory-by-manufacture'
        request={request}
        skip={!request}
      />
    </>
  )
}



