import { FormEntryField, Image } from "../../../core/type";
import uploadImageSvg from "../../../assets/upload-image.svg";
import { useState } from "react";
import { Button, Carousel, Input, Select, Space, Tooltip } from "antd";
import { ImageViewer } from "../../../core/component/Upload/ImageViewer";
import { ImageUploaderButton } from "../../../core/component/Upload";
import { DeleteOutlined } from "@ant-design/icons";
import { translate } from "../../../core";

export const ItemFormImages = ({
  value,
  onChange,
}: FormEntryField<Image[]>) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // Generate priority options
  const generatePriorityOptions = () => {
    const options = [];
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        options.push(
          <Select.Option key={i} value={i}>
            Priority {i + 1}
          </Select.Option>
        );
      }
    }
    return options;
  };

  // Handle priority change for an image
  const handlePriorityChange = (index: number, newPriority: number) => {
    const updatedImages = value ? [...value] : [];

    const duplicateIndex = updatedImages.findIndex(
      (image, i) => image.priority === newPriority && i !== index
    );

    if (duplicateIndex !== -1) {
      updatedImages[duplicateIndex].priority = updatedImages[index].priority;
    }

    updatedImages[index].priority = newPriority;
    // Sort the images by priority
    updatedImages.sort((a: any, b: any) => a.priority - b.priority);

    onChange!(updatedImages);
  };

  return (
    <Space direction="vertical">
      <Space className="d-flex flex-row justify-content-center">
        <ImageUploaderButton
          onUploaded={(image) =>
            onChange!([
              ...(value ?? []),
              { ...image, priority: value?.length ? value?.length + 1 : 0 },
            ])
          }
        />
        <Tooltip title={translate("remove", "current", "image")}>
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() =>
              onChange!(value?.filter((e) => e !== value[currentIndex]))
            }
          />
        </Tooltip>
      </Space>
      <div>
        {value && value.length > 0 ? (
          <Carousel
            afterChange={setCurrentIndex}
            dotPosition="right"
            style={{ width: 300, height: 200 }}
          >
            {value.map(({ filename, priority }, index) => (
              <div>
                <div className="bg-dark" key={Date.now()}>
                  <ImageViewer mediaURL={filename} width={250} height={200}  mediaType={'image'}/>
                </div>
                <Select
                  value={priority}
                  onChange={(value) => handlePriorityChange(index, value)}
                  placeholder="Set priority"
                  style={{
                    width: "40%",
                    margin: "10px auto",
                    display: "block",
                    bottom: "45px",
                  }}
                >
                  {generatePriorityOptions()}
                </Select>
              </div>
            ))}
          </Carousel>
        ) : (
          <EmptyImage />
        )}
      </div>
    </Space>
  );
};

const EmptyImage = () => (
  <div className="w-100 d-flex flex-row justify-content-center">
    <img
      src={uploadImageSvg}
      alt="upload"
      style={{ width: 164, height: 164 }}
    />
  </div>
);
